<template>
  <div :class="`background-auth${$vuetify.theme.dark ? '-dark' : ''}`">
  <v-toolbar class="mb-2 pl-9 pt-3 grey-background" flat height="96" :width="`calc(100% - ${$store.state.base.isExpandNavigationDrawer ? '210px' : '50px'})`" style="position: fixed; z-index: 10; padding-bottom: 54px; border-bottom: 1px solid var(--light-grey-50) !important">
      <v-toolbar-title class="d-block subtitle-1 font-weight-bold">Organizaciones</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="pr-9">
        <v-row align="center" justify="end" no-gutters>
          <v-menu close-on-click close-on-content-click offset-y :nudge-bottom="8" right transition="slide-y-transition">
            <template v-slot:activator="{on, attrs}">
              <v-btn class="px-0" v-on="on" v-bind="attrs" small outlined height="35" :ripple="false">
                <v-icon size="18" color="grey-500">mdi-filter-plus</v-icon>
              </v-btn>
            </template>
            <v-list class="px-3 py-1">
              <v-list-item v-for="(country) in countriesList.slice(0,2)" :key="country.id" class="pa-0" active-class="transparent" :ripple="false">
                <v-list-item-title class="p-0 subtitle-2 text--grey-700 font-weight-medium">
                  <div class="d-flex">
                    <v-checkbox class="ml-n1" v-model="selectedCountry" :value="country.id" dense hide-details :ripple="false" />
                      <img class="ml-1" :src="require(`@/assets/flags/flag-${country.id.toLowerCase()}.svg`)" />
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div class="pa-0 ml-2" style="min-width: 200px !important; width: 350px !important;">
            <v-text-field v-model="filterAccountText" autocomplete="off" outlined placeholder="Buscar..." prepend-inner-icon="mdi-magnify" single-line dense maxlength="64" hide-details />
          </div>
        </v-row>
      </v-toolbar-items>
    </v-toolbar>
    <div class="content px-8 mt-n5">
      <div class="mt-16 pt-10" v-if="loading" :style="`width: calc(100% - ${$store.state.base.isExpandNavigationDrawer ? '225px' : '70px'});`">
        <v-col cols="6" class="pr-3" v-for="item in 2" :key="item">
          <v-skeleton-loader class="rounded-md" type="card-heading" />
        </v-col>
      </div>
      <template v-else>
        <div v-if="accounts.length" class="mt-16 pa-0 pt-10 d-flex" :style="`width: calc(100% - ${$store.state.base.isExpandNavigationDrawer ? '225px' : '70px'});`">
          <div class="pa-0" style="flex:1; min-width: 300px">
            <v-card v-if="accounts.length" class="pa-0 pb-16 transparent" flat>
              <v-card-text class="pa-0" v-scroll:#scroll-target="onScroll" :key="reset">
                <v-col class="pr-8 pl-0" v-for="(d, i) in accounts" :key="i">
                  <v-lazy :value="page" :options="{ threshold: .5 }" transition="fade-transition">
                    <v-hover v-slot="{ hover }">
                      <v-card class="pa-0" :elevation="hover ? 6 : 0" @click="setAccount(d)" :ripple="false">
                        <v-card-text class="py-6 px-5">
                          <div class="d-flex mb-3 align-center justify-sm-space-between">
                          <v-col cols="1" class="pa-0" style="min-width: 40px;">
                            <v-icon color="blue-500" size="22">mdi-domain</v-icon>
                          </v-col>
                          <img :width="24" :src="require(`@/assets/flags/flag-${d.country.toLowerCase()}.svg`)" />
                          </div>
                          <v-row no-gutters align="center">
                            <v-col class="pa-0">
                              <span class="d-block text-truncate" style="max-width: 80%;">
                                <span class="grey-700--text font-weight-medium body-1">{{d.name}}</span>
                              </span>
                              <span class="d-block mt-1 body-2 grey-300--text">{{ d.country === 'CL' ? 'RUT' : 'RUC'}} {{d.tax_id}}</span>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-hover>
                  </v-lazy>
                </v-col>
                <div v-if="lazyLoading" class="text-center mb-n12 mt-5">
                  <v-progress-circular indeterminate color="blue-500" :width="2" :size="26" />
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="pa-0 pb-6" justify="end" style="flex:1;">
            <v-row justify="end" no-gutters style="min-height: 90vh;">
              <v-img class="ml-16" contain :src="require(`@/assets/org-${$vuetify.theme.dark ? 'dark' : 'light'}.svg`)" position="right" :aspect-ratio="13/9" style="position: fixed; bottom: 0; right: 0" />
            </v-row>
          </div>
        </div>
        <v-row v-else aling="center" justify="start" style="margin-top: 140px">
          <v-col v-if="!accountsCount || (filterAccountText.length > 0 && filterAccountText.length < 3)">
            <v-empty-state title="No existen organizaciones que coincidan con tu búsqueda." description="Modifica la búsqueda para obtener registros" type="organization" customClass="mt-6" :height="250" :isFree="true" />
          </v-col>
          <v-col v-else>
            <v-empty-state title="Tu cuenta de usuario no tiene una organización asociada." description="Solicita una organización a soporte" type="organization" customClass="mt-6" :height="250" :isFree="true" />
          </v-col>
        </v-row>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import debounce from 'lodash/debounce'
import VEmptyState from '@/components/commons/VEmptyState'

export default {
  components: {
    VEmptyState
  },
  data: () => ({
    page: 1,
    loading: false,
    lazyLoading: false,
    selectedCountry: [],
    filterAccountText: '',
    next: null,
    accounts: [],
    isScroll: false,
    scroll: 0,
    reset: 0,
    attrs: {
      boilerplate: true,
      elevation: 0
    }
  }),
  computed: {
    ...mapState({
      countriesList: state => state.base.countriesList,
      accountsCount: state => state.accounts.workspacesCount,
      accountsList: state => state.accounts.workspacesList
    })
  },
  watch: {
    filterAccountText: debounce(function () {
      if (this.filterAccountText.length > 0 && this.filterAccountText.length < 3) {
        return false
      }
      this.getAccounts()
    }, 600),
    $route () {
      this.filterAccountText = ''
      this.getInitialData()
    },
    selectedCountry (val) {
      if (val.length) this.$router.replace({ query: { country: val } }).catch(() => {})
      else this.$router.replace({ query: {} }).catch(() => {})
    }
  },
  created () {
    this.$store.dispatch('auth/SET_ACCOUNT', { account: {}, redirect: false })
    this.getInitialData()
  },
  methods: {
    getInitialData () {
      this.loading = true
      this.$store.dispatch('accounts/LIST', {
        resource: 'login/realms/axt/custom-resources/workspaces',
        query: this.$route.query
      })
      .then((response) => {
        this.next = response.data.next
        this.accounts = [...response.data]
        // this.setAccount()
      })
      .finally(() => {
        this.loading = false
      })
    },
    onScroll (e) {
      this.scroll = e.target?.scrollTop ?? 0
      this.isScroll = e.target?.scrollTop > (this.page > 1 ? e.target?.clientHeight * this.page : e.target?.clientHeight)
      if (this.next && this.isScroll) {
        this.seeMore()
      }
    },
    seeMore () {
      this.page += 1
      if (this.accountsCount) {
        this.lazyLoading = true
        this.$store.dispatch('accounts/LIST', {
          resource: 'login/realms/axt/custom-resources/workspaces',
          query: { page: this.page},
          loader: false
        })
        .then((response) => {
          this.next = response.data.next
          this.accounts = [...this.accounts, ...response.data]
          this.isScroll = false
          if (!this.next) this.page = 1
        })
        .finally(() => {
          // document.getElementById("list").scrollIntoView(true)
          this.lazyLoading = false
          this.reset += 1
        })
      }
    },
    getAccounts () {
      const query = {}

      if (this.filterAccountText) {
        query.q = this.filterAccountText
      }
      this.loading = true
      this.$store.dispatch('accounts/LIST', {
        resource: 'login/realms/axt/custom-resources/workspaces',
        query: query
      })
      .then((response) => {
        this.accounts = [...response.data]
      })
      .finally(() => {
        this.loading = false
      })
    },
    setAccount (account) {
      // track Segment page group
      // if (process.env.NODE_ENV === 'production') {
      //   window.analytics.group(account.id, {
      //     name: account.name
      //   })
      // }
      this.$store.dispatch('auth/SET_ACCOUNT', { account })
      // if (!this.$store.state.auth.userAccount.is_staff && this.$store.state.accounts.me.id === 'undefined') {
      //   this.$store.dispatch('accounts/GET_ME', {
      //     resource: 'members/me/?expand=groups'
      //   })
      // }
    }
  }
}
</script>